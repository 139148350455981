<script setup lang="ts">
const { isAuthenticated, logout, login, user } = useAuth()

const userInitials = computed(() => {
  return `${user?.value?.firstName?.charAt(0) ?? ''}${user?.value?.lastName?.charAt(0) ?? ''}`
})
</script>

<template>
  <UiDropdownMenu>
    <UiDropdownMenuTrigger as-child>
      <UiButton
        variant="ghost"
        class="relative h-8 w-8 rounded-full"
      >
        <UiAvatar
          v-if="isAuthenticated"
          class="h-8 w-8 flex items-center justify-center"
        >
          <UiAvatarImage
            :src="user?.profilePictureUrl ?? ''"
            :alt="user?.firstName + ' ' + user?.lastName"
            class="flex items-center justify-center"
          />
          <UiAvatarFallback class="flex items-center justify-center">
            {{ userInitials }}
          </UiAvatarFallback>
        </UiAvatar>
        <UiAvatar
          v-else
          class="h-8 w-8 flex items-center justify-center"
        >
          <UiAvatarFallback class="flex items-center justify-center">
            <SmartIcon
              :size="18"
              class=""
              name="mdi:account"
            />
          </UiAvatarFallback>
        </UiAvatar>
      </UiButton>
    </UiDropdownMenuTrigger>
    <UiDropdownMenuContent
      class="w-56"
      align="end"
    >
      <UiDropdownMenuLabel
        v-if="isAuthenticated"
        class="font-normal flex"
      >
        <div class="flex">
          <UiAvatar
            class="h-8 w-8 flex items-center justify-center mr-2"
          >
            <UiAvatarImage
              :src="user?.profilePictureUrl ?? ''"
              :alt="user?.firstName + ' ' + user?.lastName"
              class="flex items-center justify-center"
            />
            <UiAvatarFallback class="flex items-center justify-center">
              {{ userInitials }}
            </UiAvatarFallback>
          </UiAvatar>
          <div class="flex flex-col space-y-1">
            <p class="text-sm font-medium leading-none">
              {{ user?.firstName }} {{ user?.lastName }}
            </p>
            <p class="text-xs leading-none text-muted-foreground">
              {{ user?.email }}
            </p>
          </div>
        </div>
      </UiDropdownMenuLabel>
      <UiDropdownMenuSeparator v-if="isAuthenticated" />
      <UiDropdownMenuItem
        v-if="isAuthenticated"
        @click="logout"
      >
        <SmartIcon
          name="mdi:logout"
          class="mr-2 size-4"
        />
        <span>
          Log out
        </span>
      </UiDropdownMenuItem>
      <UiDropdownMenuItem
        v-else
        @click="login()"
      >
        <SmartIcon
          name="mdi:login"
          class="mr-2 size-4"
        />
        <span>
          Log in
        </span>
      </UiDropdownMenuItem>
    </UiDropdownMenuContent>
  </UiDropdownMenu>
</template>
